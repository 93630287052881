import { createTheme } from '@mui/material/styles';

const theme = createTheme({
	palette: {
		primary: {
			dark: '#275737',
			main: '#3f8a58',
			light: '#71c78e',
		},
		// primary: {
		// 	dark: '#039196',
		// 	main: '#03989e',
		// 	light: '#05dae1',
		// },
		secondary: {
			dark: '#005566',
			main: '#7ed99c',
			light: '#00aacc',
		},
		info: {
			dark: '#03797d',
			main: '#3f8a58',
			light: '#96cfa9',
		},
	},

	components: {
		MuiTypography: {
			defaultProps: {
				variantMapping: {
					body2: 'span',
				},
			},
		},
	},
});

export default theme;
