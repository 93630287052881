import { makeAutoObservable } from 'mobx';
export interface ExportToExcel {
	openDialog: boolean;
}

class ExportExcelDialogStore {
	dialog: ExportToExcel = { openDialog: false } as ExportToExcel;

	constructor() {
		makeAutoObservable(this);
	}
	setOpenDialog(payload: boolean) {
		this.dialog.openDialog = payload;
	}
}

export default new ExportExcelDialogStore();
