import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import PrivateLayout from '../layout/PrivateLayout';
// * dashboard
const Dashboard = lazy(() => import('modules/private/pages/dashboard/dashboard'));
// * transactions
const CalendarPage = lazy(() => import('modules/private/pages/transactions/calendar/CalendarPage'));
const PatientPage = lazy(() => import('modules/private/pages/transactions/patients/PatientPage'));
// * Patients Components
const PatientViewDetails = lazy(
	() => import('modules/private/pages/transactions/patients/components/PatientViewDetails')
);
const ConsultationDetails = lazy(() => import('modules/private/pages/transactions/patients/tabs/ConsultationDetails'));
const ConsultationPrescription = lazy(
	() => import('modules/private/pages/transactions/patients/tabs/ConsultationPrescription')
);
const ConsultationDocuments = lazy(
	() => import('modules/private/pages/transactions/patients/tabs/ConsultationDocuments')
);
const ConsultationInstructions = lazy(
	() => import('modules/private/pages/transactions/patients/tabs/ConsultationInstructions')
);
const ConsultationPersonalInformation = lazy(
	() => import('modules/private/pages/transactions/patients/tabs/ConsultationPersonalInformation')
);
const UploadMemberTransactionsPage = lazy(
	() => import('modules/private/pages/transactions/patients/components/UploadMemberTransactions')
);

// * datacenter
const DoctorPage = lazy(() => import('modules/private/pages/datacenter/doctors/DoctorPage'));
const EmployeePage = lazy(() => import('modules/private/pages/datacenter/employees/EmployeePage'));
// * maintenance
// const ExportToExcel = lazy(() => import('modules/private/pages/maintenance/export-to-excel/ExportToExcel'));
const DocumentTypePage = lazy(() => import('modules/private/pages/maintenance/document-type/DocumentTypePage'));
const SpecializationPage = lazy(() => import('modules/private/pages/maintenance/specialization/SpecializationPage'));
const ItemGeneric = lazy(() => import('modules/private/pages/maintenance/item-generic/ItemGeneric'));
const ItemCategoryPage = lazy(() => import('modules/private/pages/maintenance/item-category/ItemCategoryPage'));
const ItemPage = lazy(() => import('modules/private/pages/maintenance/items/ItemPage'));
const SitesPage = lazy(() => import('modules/private/pages/maintenance/sites/SitesPage'));
const InstructionCategory = lazy(
	() => import('modules/private/pages/maintenance/instruction-category/InstructionCategory')
);
const UserGroupPage = lazy(() => import('modules/private/pages/maintenance/user-group/UserGroupPage'));
const Services = lazy(() => import('modules/private/pages/maintenance/services/Services'));
const Impressions = lazy(() => import('modules/private/pages/maintenance/impressions/Impressions'));
// * reports
const TestReport = lazy(() => import('modules/private/pages/reports/TestReport'));
// * users
const UserAccessPage = lazy(() => import('modules/private/pages/user-access/UserAccessPage'));
const UserAccessItemTagging = lazy(() => import('modules/private/pages/user-access/UserAccessUpdateAccessPage'));
const UserAccessProfile = lazy(() => import('modules/private/pages/user-access/UserAccessUpdateProfilePage'));
const UserLogsPage = lazy(() => import('modules/private/pages/user-logs/UserLogsPage'));
// * not found
const NotFound = lazy(() => import('modules/private/pages/NotFound/NotFound'));
const route: RouteObject = {
	path: '',
	element: <PrivateLayout />,
	children: [
		{
			path: '',
			element: <Dashboard />,
		},
		{
			path: 'dashboard',
			element: <Dashboard />,
		},
		{
			path: '/calendar',
			element: <CalendarPage />,
		},
		{
			path: '/members',
			element: <PatientPage />,
		},
		{
			path: '/members-view-details',
			element: <PatientViewDetails />,
		},
		{
			path: '/consultation-details',
			element: <ConsultationDetails />,
		},
		{
			path: '/consultation-prescription',
			element: <ConsultationPrescription />,
		},
		{
			path: '/consultation-documents',
			element: <ConsultationDocuments />,
		},
		{
			path: '/consultation-instructions',
			element: <ConsultationInstructions />,
		},
		{
			path: '/consultation-personal-information',
			element: <ConsultationPersonalInformation />,
		},
		{
			path: '/doctors',
			element: <DoctorPage />,
		},
		{
			path: '/employees',
			element: <EmployeePage />,
		},
		// {
		// 	path: '/export-to-excel',
		// 	element: <ExportToExcel />,
		// },
		{
			path: '/document-type',
			element: <DocumentTypePage />,
		},
		{
			path: '/specialization',
			element: <SpecializationPage />,
		},
		{
			path: '/item-generic',
			element: <ItemGeneric />,
		},
		{
			path: '/item-category',
			element: <ItemCategoryPage />,
		},
		{
			path: '/items',
			element: <ItemPage />,
		},
		{
			path: '/sites',
			element: <SitesPage />,
		},
		{
			path: '/services',
			element: <Services />,
		},
		{
			path: '/impressions',
			element: <Impressions />,
		},
		{
			path: '/instruction-category',
			element: <InstructionCategory />,
		},
		{
			path: '/user-access',
			element: <UserAccessPage />,
		},
		{
			path: '/user-update-access',
			element: <UserAccessItemTagging />,
		},
		{
			path: '/test-report',
			element: <TestReport />,
		},

		{
			path: '/user-update-profile',
			element: <UserAccessProfile />,
		},
		{
			path: '/user-logs',
			element: <UserLogsPage />,
		},
		{
			path: '/user-groups',
			element: <UserGroupPage />,
		},
		{
			path: '/members/upload-transactions',
			element: <UploadMemberTransactionsPage />,
		},
		{ path: '*', element: <NotFound /> },
	],
};

export default route;
