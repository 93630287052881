// * icon/logo
import logo from 'assets/images/NWTFLogo-PNG.png';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import SummarizeIcon from '@mui/icons-material/Summarize';
// *
import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { HSideBar } from '@hybrain/mui.ui.navigation.h-side-bar';
import { styled } from '@mui/material/styles';
import { HLoadingIndicator } from '@hybrain/mui.ui.feedback.h-loading-indicator';
import { Outlet, useNavigate } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';

import api from 'api';
import localforage from 'localforage';
import { useMediaQuery } from '@mui/material';
import useApi from 'api/useApi';
import ChangePasswordDialog from '../pages/user-access/ChangePasswordDialog';
import LayoutStore from 'stores/LayoutStore';
import { observer } from 'mobx-react-lite';
import ExportExcelDialogStore from 'stores/ExportExcelDialogStore';
import ExportToExcel from '../pages/maintenance/export-to-excel/ExportToExcel';
import {
	Badge,
	CalendarMonth,
	Category,
	Class,
	DocumentScanner,
	FactCheck,
	FolderSpecial,
	Group,
	Groups,
	Inventory,
	Inventory2,
	List,
	LocalHospital,
	MedicalServices,
	PeopleAlt,
	ReceiptLong,
	Settings,
	VerifiedUser,
	WhereToVote,
	Download,
} from '@mui/icons-material';
import AuthStore from 'stores/AuthStore';

interface PrivateLayoutProps {}

const HSideBarStyled = styled(HSideBar)(({ theme }) => ({
	'& .MuiPaper-root': {
		backgroundColor: theme.palette.primary.main,
		color: 'white',
	},
}));

const LogoutButton = styled('div')(({ theme }) => ({
	display: 'flex',
	columnGap: 8,
	alignItems: 'center',
	cursor: 'pointer',
	fontSize: 20,
	fontWeight: 500,
	color: theme.palette.primary.main,
	transition: '300ms',

	'& svg': {
		fill: theme.palette.primary.main,
	},

	'&:hover': {
		backgroundColor: theme.palette.primary.main,
		color: '#b3b3b5',
	},

	'&:hover svg': {
		fill: '#b3b3b5',
	},
}));

const PrivateLayout: React.FC<PrivateLayoutProps> = () => {
	const navigate = useNavigate();
	const [sideBarState, setSideBarState] = useState(true);
	const [reports, setReports] = useState<any[]>(() => []);
	const [topSideBarItemsList, setTopSideBarItemsList] = useState([] as any);
	const [userAccessItems, setUserAccessItems] = useState([] as any);
	const [reportAccessList, setReportAccessList] = useState([] as any);
	const [employeeSideBarName, setEmployeeSideBarName] = useState({ CompleteName: '', Initials: '' });
	const [currentUser, setCurrentUser] = useState({} as any);
	const [promptChangePassword, setPromptChangePassword] = useState(false);
	const [isFirstTimeLogin, setIsFirstTimeLogin] = useState(false);
	const Screenwidth = useMediaQuery('(min-width:1000px)');

	const { callApi: checkIfUserUsingDefaultPassword } = useApi({
		url: '/users/check-for-defaultpass',
		method: 'post',
		success: false,
		onSuccess: (data) => {
			if (data.result.isUsingDefaultPassword) setPromptChangePassword(true);
		},
	});

	const { callApi: checkPasswordExpiry } = useApi({
		url: '/users/check-password-expiry/' + currentUser.UseraccessID ?? 0,
		method: 'get',
		success: false,
		dependencies: [currentUser],
		onSuccess: (data) => {
			if (currentUser.UseraccessID > 0) {
				setPromptChangePassword(!!!data.response.isPasswordValid);
				setIsFirstTimeLogin(data.response.isFirstTimeLogin);
			}
		},
		onBeforeApiCall: () => {
			if (!currentUser.UseraccessID) {
				return false;
			}
		},
	});

	const initializeReports = useCallback(() => {
		api()
			.get<any>('reports/list/active')
			.then(({ data }) => {
				let reportsNavList = data.response.map((reports: any) => {
					return {
						text: reports.ReportName,
						icon: (
							<Tooltip title={reports.ReportName} placement="right">
								<SummarizeIcon sx={{ color: 'white' }} />
							</Tooltip>
						),
						path: reports.path,
					};
				});
				setReports(reportsNavList);
			});
	}, []);

	useEffect(() => {
		initializeReports();
	}, [initializeReports]);

	useEffect(() => {
		setSideBarState(Screenwidth);
		LayoutStore.setSideBarState(Screenwidth);
	}, [Screenwidth]);

	useEffect(() => {
		(async () => {
			try {
				localforage.getItem<any>('user').then((user) => {
					if (user != null) {
						setCurrentUser(user);
						let name = user.datacenter?.Firstname + ' ' + user.datacenter?.Lastname;
						let initials =
							user.datacenter?.Firstname.substring(0, 1) + '. ' + user.datacenter?.Lastname.substring(0, 1) + '.';
						setEmployeeSideBarName({ CompleteName: name.toUpperCase(), Initials: initials.toUpperCase() });
						checkIfUserUsingDefaultPassword(user);
						checkPasswordExpiry();
					} else {
						navigate('/login');
					}
				});
			} catch (err) {}

			//fetch user access items
			try {
				let sidebarItemsList = [] as any;
				await localforage.getItem<any>('userAccess').then((userAccess) => {
					if (userAccess != null) {
						setUserAccessItems(userAccess);
						//clear sidebar items
						setTopSideBarItemsList([] as any);

						//get accessible items
						let accessibleUseraccessItems = userAccess.filter(
							(x: any) => x.Value === true && x.SystemCategory === 'NWTF_TELEMED'
						);

						//check access for Transactions
						let hasAccessTransactions = !!accessibleUseraccessItems.filter(
							(x: any) => x.MainNavigation === 'TRANSACTIONS'
						).length;
						if (hasAccessTransactions) {
							let transactionsSubItems = [] as any;
							//check access for Transactions - Calendar
							let hasAccessCalendar = !!accessibleUseraccessItems.filter((x: any) => x.SubNavigationNo === 1).length;
							if (hasAccessCalendar) {
								transactionsSubItems.push({
									text: 'Calendar',
									icon: <CalendarMonth sx={{ color: 'white' }} />,
									path: '/calendar',
								});
							}

							//check access for Transactions - Patients
							let hasAccessTransactions = !!accessibleUseraccessItems.filter(
								(x: any) => x.SubNavigationNo >= 2 && x.SubNavigationNo <= 5
							).length;
							if (hasAccessTransactions) {
								transactionsSubItems.push({
									text: 'Members',
									icon: <Groups sx={{ color: 'white' }} />,
									path: '/members',
								});
							}
							sidebarItemsList.push({
								text: 'Transactions',
								icon: <ReceiptLong sx={{ color: 'white' }} />,
								subItems: transactionsSubItems,
							});
						}

						//check access for datacenter
						let hasAccessDataCenter = !!accessibleUseraccessItems.filter((x: any) => x.MainNavigation === 'DATACENTER')
							.length;
						if (hasAccessDataCenter) {
							let datacenterSubItems = [] as any;

							//check access for Datacenter - Doctors
							let hasAccessDoctors = !!accessibleUseraccessItems.filter(
								(x: any) => x.SubNavigationNo >= 6 && x.SubNavigationNo <= 9
							).length;
							if (hasAccessDoctors) {
								datacenterSubItems.push({
									text: 'Doctors',
									icon: <LocalHospital sx={{ color: 'white' }} />,
									path: '/doctors',
								});
							}

							//check access for Datacenter - Employees
							let hasAccessEmployees = !!accessibleUseraccessItems.filter(
								(x: any) => x.SubNavigationNo >= 10 && x.SubNavigationNo <= 13
							).length;
							if (hasAccessEmployees) {
								datacenterSubItems.push({
									text: 'Employees',
									icon: <Badge sx={{ color: 'white' }} />,
									path: '/employees',
								});
							}

							sidebarItemsList.push({
								text: 'DataCenter',
								icon: <PeopleAlt sx={{ color: 'white' }} />,
								subItems: datacenterSubItems,
							});
						}

						//check access for Maintenance
						let hasAccessMaintenance = !!accessibleUseraccessItems.filter(
							(x: any) => x.MainNavigation === 'MAINTENANCE'
						).length;
						if (hasAccessMaintenance) {
							let maintenanceSubDepartments = [] as any;

							//check access for Maintenance - Document Type
							let hasAccessDocumentType = !!accessibleUseraccessItems.filter((x: any) => x.SubNavigationNo === 14)
								.length;
							if (hasAccessDocumentType) {
								maintenanceSubDepartments.push({
									text: 'Document Type',
									icon: <DocumentScanner sx={{ color: 'white' }} />,
									path: '/document-type',
								});
							}

							//check access for Maintenance - Specialization
							let hasAccessSpecialization = !!accessibleUseraccessItems.filter((x: any) => x.SubNavigationNo === 15)
								.length;
							if (hasAccessSpecialization) {
								maintenanceSubDepartments.push({
									text: 'Specialization',
									icon: <FolderSpecial sx={{ color: 'white' }} />,
									path: '/specialization',
								});
							}

							//check access for Maintenance - Item Generic
							let hasAccessItemGeneric = !!accessibleUseraccessItems.filter((x: any) => x.SubNavigationNo === 16)
								.length;
							if (hasAccessItemGeneric) {
								maintenanceSubDepartments.push({
									text: 'Item Generic',
									icon: <Inventory2 sx={{ color: 'white' }} />,
									path: '/item-generic',
								});
							}

							//check access for Maintenance - Item Category
							let hasAccessItemCategory = !!accessibleUseraccessItems.filter((x: any) => x.SubNavigationNo === 17)
								.length;
							if (hasAccessItemCategory) {
								maintenanceSubDepartments.push({
									text: 'Item Category',
									icon: <Category sx={{ color: 'white' }} />,
									path: '/item-category',
								});
							}

							//check access for Maintenance - Items
							let hasAccessItems = !!accessibleUseraccessItems.filter((x: any) => x.SubNavigationNo === 18).length;
							if (hasAccessItems) {
								maintenanceSubDepartments.push({
									text: 'Items',
									icon: <Inventory sx={{ color: 'white' }} />,
									path: '/items',
								});
							}

							//check access for Maintenance - Sites
							let hasAccessSites = !!accessibleUseraccessItems.filter((x: any) => x.SubNavigationNo === 19).length;
							if (hasAccessSites) {
								maintenanceSubDepartments.push({
									text: 'Sites',
									icon: <WhereToVote sx={{ color: 'white' }} />,
									path: '/sites',
								});
							}

							//check access for Maintenance - Instruction Category
							let hasAccessInstructionCategory = !!accessibleUseraccessItems.filter(
								(x: any) => x.SubNavigationNo === 20
							).length;
							if (hasAccessInstructionCategory) {
								maintenanceSubDepartments.push({
									text: 'Instruction Category',
									icon: <Class sx={{ color: 'white' }} />,
									path: '/instruction-category',
								});
							}

							//check access for Maintenance - Services
							let hasAccessServices = !!accessibleUseraccessItems.filter((x: any) => x.SubNavigationNo === 21).length;
							if (hasAccessServices) {
								maintenanceSubDepartments.push({
									text: 'Services',
									icon: <MedicalServices sx={{ color: 'white' }} />,
									path: '/services',
								});
							}

							//check access for Maintenance - User groups
							let hasAccessUserGroups = !!accessibleUseraccessItems.filter((x: any) => x.SubNavigationNo === 25).length;
							if (hasAccessUserGroups) {
								maintenanceSubDepartments.push({
									text: 'User Groups',
									icon: <Group sx={{ color: 'white' }} />,
									path: '/user-groups',
								});
							}

							//check access for Maintenance - User groups
							let hasAccessImpressions = !!accessibleUseraccessItems.filter((x: any) => x.SubNavigationNo === 27)
								.length;
							if (hasAccessImpressions) {
								maintenanceSubDepartments.push({
									text: 'Impressions',
									icon: <List sx={{ color: 'white' }} />,
									path: '/impressions',
								});
							}

							maintenanceSubDepartments.push({
								text: 'Export to Excel',
								icon: <Download sx={{ color: 'white' }} />,
								onClick: () => ExportExcelDialogStore.setOpenDialog(true),
								// path: '/export-to-excel',
							});

							sidebarItemsList.push({
								text: 'Maintenance',
								icon: <Settings sx={{ color: 'white' }} />,
								subItems: maintenanceSubDepartments,
							});
						}

						//check access for User Access
						let hasAccessUserAccess = !!accessibleUseraccessItems.filter((x: any) => x.MainNavigation === 'USER ACCESS')
							.length;
						if (hasAccessUserAccess) {
							sidebarItemsList.push({
								text: 'User Access',
								icon: <VerifiedUser sx={{ color: 'white' }} />,
								path: '/user-access',
							});
						}

						//check access for User Logs
						let hasAccessUserLogs = !!accessibleUseraccessItems.filter((x: any) => x.MainNavigation === 'USER LOGS')
							.length;
						if (hasAccessUserLogs) {
							sidebarItemsList.push({
								text: 'User Logs',
								icon: <FactCheck sx={{ color: 'white' }} />,
								path: '/user-logs',
							});
						}
					}
					setTopSideBarItemsList(sidebarItemsList);
				});
			} catch (error) {
				console.error(error);
			}
		})();
	}, []); //[initializeReports, reports]

	return (
		<div className="flex min-h-screen">
			<CssBaseline />
			<HSideBarStyled
				mini
				className="m-0"
				drawerWidth="270px"
				open={sideBarState}
				drawerMiniWidth="73px !important"
				onClose={() => {
					setSideBarState(!sideBarState);
					LayoutStore.setSideBarState(!sideBarState);
				}}
				selectedLinkBackgroundColor={(theme) => theme.palette.primary.light}
				expandIcon={
					<Grid container display="flex" justifyContent="center">
						{/* {sideBarState ? (
							<Grid item xs={sideBarState ? 10 : 0}>
								<img
									alt=""
									style={{
										width: sideBarState ? 'auto' : '60px',
										transition: '300ms',
										height: '35px',
									}}
									src={logo}
								/>
							</Grid>
						) : (
							''
						)} */}
						<Grid item xs={sideBarState ? 12 : 12} style={{ textAlign: 'right' }}>
							<MenuIcon
								sx={{ color: 'white' }}
								style={{ height: '35px', width: '100%', margin: sideBarState ? '0 0 0 7px ' : 0 }}
							/>
						</Grid>
					</Grid>
				}
				headerContent={
					<Grid container display="flex" justifyContent="center">
						<img
							alt=""
							style={{
								width: sideBarState ? '90%' : '60px',
								transition: '300ms',
								margin: '30px, 0',
							}}
							src={logo}
						/>
					</Grid>
				}
				topSidebarItems={topSideBarItemsList}
				bodyContent={
					<>
						<div className="flex flex-grow" />
						<div style={{ backgroundColor: '#b3b3b5', paddingTop: '10px' }}>
							<div className="text-center">
								{/* <img
									alt=""
									src={noImage}
									style={{
										margin: 'auto',
										borderRadius: '50%',
										transition: 'width 300ms,  height 300ms',
										height: sideBarState ? '120px' : '60px',
										width: sideBarState ? '120px' : '60px',
									}}
								/> */}

								<div
									className="font-bold mt-5 "
									style={{ cursor: 'pointer' }}
									onClick={() => {
										let obj = currentUser;
										obj.accessIsProfileOnly = true;
										navigate('/user-update-profile', { state: obj });
									}}
								>
									{sideBarState ? employeeSideBarName?.CompleteName ?? '' : employeeSideBarName?.Initials ?? ''}
								</div>
							</div>
							<Tooltip title="Logout" placement="top">
								<LogoutButton
									className="mt-3 py-3 px-5"
									onClick={() => {
										localforage.clear();
										AuthStore.setUser({} as any);
										navigate('/login');
									}}
								>
									<LogoutIcon sx={{ marginRight: '15px' }} /> {sideBarState ? 'Logout' : ''}
								</LogoutButton>
							</Tooltip>
						</div>
					</>
				}
			/>
			<ChangePasswordDialog
				open={promptChangePassword}
				isFirstTimeLogin={isFirstTimeLogin}
				onClose={() => {}}
			></ChangePasswordDialog>
			<ExportToExcel
				open={ExportExcelDialogStore.dialog.openDialog}
				onClose={() => ExportExcelDialogStore.setOpenDialog(false)}
			/>
			<Suspense
				fallback={
					<div className="flex-grow">
						<HLoadingIndicator />
					</div>
				}
			>
				<Outlet></Outlet>
			</Suspense>
		</div>
	);
};

export default observer(PrivateLayout);
