import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import BaseDialog from 'dialogs/BaseDialog';
import { observer, useAsObservableSource } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import AlbumIcon from '@mui/icons-material/Album';
import DownloadIcon from '@mui/icons-material/Download';
import BaseButton from 'inputs/BaseButton';
import useApi from 'api/useApi';
import { Description } from '@mui/icons-material';
import { setItem } from 'localforage';
import BaseSelect from 'inputs/BaseSelect';
import CircularProgress from '@mui/material/CircularProgress';
var XLSX = require('xlsx');
interface ExportToExcelProps {
	open: boolean;
	onClose: () => void;
}

const ExportToExcel: React.FC<ExportToExcelProps> = ({ ...props }) => {
	const [arr, setArr] = useState<any[]>([]);
	const [singleSheetName, setSingleSheetName] = useState('');
	const [downloadSingle, setDownloadSingle] = useState(false);
	const [downloadMultiple, setDownloadMultiple] = useState(false);
	const [loading, setLoading] = useState(false);
	const [servicesArr, setServicesArr] = useState<any[]>([]);
	const [doctorsArr, setDoctorsArr] = useState<any[]>([]);
	const [itemsArr, setItemsArr] = useState<any[]>([]);
	const [itemCategoryArr, setItemCategoryArr] = useState<any[]>([]);
	const [siteBranchArr, setSiteBranchArr] = useState<any[]>([]);
	const [docTypeArr, setDocTypeArr] = useState<any[]>([]);
	const [membersArr, setMembersArr] = useState<any[]>([]);
	const [instructionCatArr, setInstructionCatMembersArr] = useState<any[]>([]);
	const [siteBranchID, SetSiteBranchID] = useState(0);
	const [loadingServices, setLoadingServices] = useState(false);
	const [loadingDoctors, setLoadingDoctors] = useState(false);
	const [loadingItems, setLoadingItems] = useState(false);
	const [loadingItemCategory, setLoadingItemCategory] = useState(false);
	const [loadingSiteBranch, setLoadingSiteBranch] = useState(false);
	const [loadingDocumentTypes, setLoadingDocumentTypes] = useState(false);
	const [loadingMembers, setLoadingMembers] = useState(false);
	const [loadingInstructions, setLoadingInstructions] = useState(false);
	const { callApi: callApiServices } = useApi({
		url: 'services/list/active',
		immediate: false,
		onSuccess({ response }) {
			setArr([Object.keys(response?.[0])]);
			response.forEach((element: any) => {
				setArr((arrPrev) => [...arrPrev, Object.values(element)]);
			});

			// ! for multiple

			setServicesArr([Object.keys(response?.[0])]);
			response.forEach((element: any) => {
				setServicesArr((arrPrev) => [...arrPrev, Object.values(element)]);
			});
		},
	});
	const { callApi: callApiDoctors } = useApi({
		url: 'datacenter/doctors',
		immediate: false,
		onSuccess({ response }) {
			let filteredDoctors = response
				.map((mappedDoctor: any) => ({
					...mappedDoctor,
					specialization: mappedDoctor.specialization.Description,
				}))
				.filter((filtered: any) => filtered.isActive === true);
			setArr([Object.keys(filteredDoctors?.[0])]);
			filteredDoctors.forEach((element: any) => {
				setArr((arrPrev) => [...arrPrev, Object.values(element)]);
			});

			// ! for multiple

			setDoctorsArr([Object.keys(filteredDoctors?.[0])]);
			filteredDoctors.forEach((element: any) => {
				setDoctorsArr((arrPrev) => [...arrPrev, Object.values(element)]);
			});
		},
	});
	const { callApi: callApiItems } = useApi({
		url: 'items/list/active',
		immediate: false,
		onSuccess({ response }) {
			setArr([Object.keys(response?.[0])]);
			response.forEach((element: any) => {
				setArr((arrPrev) => [...arrPrev, Object.values(element)]);
			});

			// ! for multiple

			setItemsArr([Object.keys(response?.[0])]);
			response.forEach((element: any) => {
				setItemsArr((arrPrev) => [...arrPrev, Object.values(element)]);
			});
		},
	});
	const { callApi: callApiItemCategory } = useApi({
		url: 'item-category/list/active',
		immediate: false,
		onSuccess({ response }) {
			setArr([Object.keys(response?.[0])]);
			response.forEach((element: any) => {
				setArr((arrPrev) => [...arrPrev, Object.values(element)]);
			});

			// ! for multiple

			setItemCategoryArr([Object.keys(response?.[0])]);
			response.forEach((element: any) => {
				setItemCategoryArr((arrPrev) => [...arrPrev, Object.values(element)]);
			});
		},
	});
	const { callApi: callApiSiteBranch } = useApi({
		url: 'site-branch/list/active',
		immediate: false,
		onSuccess({ response }) {
			setArr([Object.keys(response?.[0])]);
			response.forEach((element: any) => {
				setArr((arrPrev) => [...arrPrev, Object.values(element)]);
			});

			// ! for multiple

			setSiteBranchArr([Object.keys(response?.[0])]);
			response.forEach((element: any) => {
				setSiteBranchArr((arrPrev) => [...arrPrev, Object.values(element)]);
			});
		},
	});
	const { callApi: callApiDocType } = useApi({
		url: 'document-type/list/active',
		immediate: false,
		onSuccess({ response }) {
			setArr([Object.keys(response?.[0])]);
			response.forEach((element: any) => {
				setArr((arrPrev) => [...arrPrev, Object.values(element)]);
			});

			// ! for multiple

			setDocTypeArr([Object.keys(response?.[0])]);
			response.forEach((element: any) => {
				setDocTypeArr((arrPrev) => [...arrPrev, Object.values(element)]);
			});
		},
	});

	const { callApi: callApiMembers } = useApi({
		url: `patients/list/active/site-branch/${siteBranchID}`,
		immediate: false,
		onSuccess({ response }) {
			setArr([Object.keys(response?.[0])]);
			response.forEach((element: any) => {
				setArr((arrPrev) => [...arrPrev, Object.values(element)]);
			});

			// ! for multiple

			setMembersArr([Object.keys(response?.[0])]);
			response.forEach((element: any) => {
				setMembersArr((arrPrev) => [...arrPrev, Object.values(element)]);
			});
		},
	});

	// const { callApi: callApiMembers } = useApi({
	// 	url: 'patients/list/active',
	// 	immediate: false,
	// 	onSuccess({ response }) {
	// 		setArr([Object.keys(response?.[0])]);
	// 		response.forEach((element: any) => {
	// 			setArr((arrPrev) => [...arrPrev, Object.values(element)]);
	// 		});

	// 		// ! for multiple

	// 		setMembersArr([Object.keys(response?.[0])]);
	// 		response.forEach((element: any) => {
	// 			setMembersArr((arrPrev) => [...arrPrev, Object.values(element)]);
	// 		});
	// 	},
	// });
	const { callApi: callApiInstuctionCat } = useApi({
		url: 'instruction-category/list/active',
		immediate: false,
		onSuccess({ response }) {
			setArr([Object.keys(response?.[0])]);
			response.forEach((element: any) => {
				setArr((arrPrev) => [...arrPrev, Object.values(element)]);
			});

			// ! for multiple

			setInstructionCatMembersArr([Object.keys(response?.[0])]);
			response.forEach((element: any) => {
				setInstructionCatMembersArr((arrPrev) => [...arrPrev, Object.values(element)]);
			});
		},
	});

	const { data: sites } = useApi<any[]>({
		url: 'site-branch/list/active',
		onSuccess: ({ response }) => {
			let firstLoad = response?.[0];
			SetSiteBranchID(firstLoad.SiteBranchID);
		},
	});

	useEffect(() => {
		if (arr.length > 0 && downloadSingle) handleSubmitSingleArray();
	}, [arr]);

	const handleSubmitSingleArray = () => {
		var newWB = XLSX.utils.book_new();
		const workSheet = XLSX.utils.aoa_to_sheet(arr);
		XLSX.utils.book_append_sheet(newWB, workSheet, singleSheetName);
		XLSX.writeFile(newWB, singleSheetName + '.xlsx');
		setDownloadSingle(false);
		setLoadingServices(false);
		setLoadingDoctors(false);
		setLoadingItems(false);
		setLoadingItemCategory(false);
		setLoadingSiteBranch(false);
		setLoadingDocumentTypes(false);
		setLoadingMembers(false);
		setLoadingInstructions(false);
	};

	useEffect(() => {
		if (
			servicesArr.length > 0 &&
			doctorsArr.length > 0 &&
			itemsArr.length > 0 &&
			itemCategoryArr.length > 0 &&
			siteBranchArr.length > 0 &&
			docTypeArr.length > 0 &&
			membersArr.length > 0 &&
			instructionCatArr.length > 0 &&
			downloadMultiple
		)
			handleSubmitMultipleArray();
	}, [servicesArr, doctorsArr, itemsArr, itemCategoryArr, siteBranchArr, docTypeArr, membersArr, instructionCatArr]);

	const handleSubmitMultipleArray = () => {
		var newWB = XLSX.utils.book_new();
		const workSheetServices = XLSX.utils.aoa_to_sheet(servicesArr);
		const workSheetDoctors = XLSX.utils.aoa_to_sheet(doctorsArr);
		const workSheetItems = XLSX.utils.aoa_to_sheet(itemsArr);
		const workSheetItemCategory = XLSX.utils.aoa_to_sheet(itemCategoryArr);
		const workSheetSiteBranch = XLSX.utils.aoa_to_sheet(siteBranchArr);
		const workSheetDocType = XLSX.utils.aoa_to_sheet(docTypeArr);
		const workSheetMembers = XLSX.utils.aoa_to_sheet(membersArr);
		const workSheetInstructionCategory = XLSX.utils.aoa_to_sheet(instructionCatArr);
		XLSX.utils.book_append_sheet(newWB, workSheetServices, 'M_Services', true);
		XLSX.utils.book_append_sheet(newWB, workSheetDoctors, 'M_Datacenter', true);
		XLSX.utils.book_append_sheet(newWB, workSheetItems, 'M_Items', true);
		XLSX.utils.book_append_sheet(newWB, workSheetItemCategory, 'M_ItemCategory', true);
		XLSX.utils.book_append_sheet(newWB, workSheetSiteBranch, 'M_SiteBranch', true);
		XLSX.utils.book_append_sheet(newWB, workSheetDocType, 'M_DocumentTypes', true);
		XLSX.utils.book_append_sheet(newWB, workSheetMembers, 'T_Patients', true);
		XLSX.utils.book_append_sheet(newWB, workSheetInstructionCategory, 'M_InstructionCategory', true);
		XLSX.writeFile(newWB, 'ExportAll.xlsx');
		setDownloadSingle(false);
		setLoading(false);
		setDownloadMultiple(false);
	};

	return (
		<>
			<BaseDialog title="Export to Excel" maxWidth="xs" open={props.open} onClose={props.onClose}>
				<BaseSelect
					value={siteBranchID}
					label="Site Branch(for Members)"
					options={sites}
					optionText="Description"
					optionValue="SiteBranchID"
					fullWidth
					onOptionChange={(e) => SetSiteBranchID(e.SiteBranchID)}
					// inputProps={{
					// 	onChange: (e: any) => {
					// 		callApi({ SiteBranchID: e.target.value, Keyword: searchParams.Keyword });
					// 	},
					// }}
				/>
				<List>
					<ListItem>
						{loadingServices ? (
							<CircularProgress />
						) : (
							<ListItemButton
								onClick={() => {
									callApiServices();
									setSingleSheetName('M_Services');
									setDownloadSingle(true);
									setLoadingServices(true);
								}}
							>
								<ListItemIcon>
									<DownloadIcon />
								</ListItemIcon>
								<ListItemText primary="Services" />
							</ListItemButton>
						)}
					</ListItem>
					<ListItem>
						{loadingDoctors ? (
							<CircularProgress />
						) : (
							<ListItemButton
								onClick={() => {
									callApiDoctors();
									setSingleSheetName('M_Datacenter');
									setDownloadSingle(true);
									setLoadingDoctors(true);
								}}
							>
								<ListItemIcon>
									<DownloadIcon />
								</ListItemIcon>
								<ListItemText primary="Doctors" />
							</ListItemButton>
						)}
					</ListItem>
					<ListItem>
						{loadingItems ? (
							<CircularProgress />
						) : (
							<ListItemButton
								onClick={() => {
									callApiItems();
									setSingleSheetName('M_Items');
									setDownloadSingle(true);
									setLoadingItems(true);
								}}
							>
								<ListItemIcon>
									<DownloadIcon />
								</ListItemIcon>
								<ListItemText primary="Items" />
							</ListItemButton>
						)}
					</ListItem>
					<ListItem>
						{loadingItemCategory ? (
							<CircularProgress />
						) : (
							<ListItemButton
								onClick={() => {
									callApiItemCategory();
									setSingleSheetName('M_ItemCategory');
									setDownloadSingle(true);
									setLoadingItemCategory(true);
								}}
							>
								<ListItemIcon>
									<DownloadIcon />
								</ListItemIcon>
								<ListItemText primary="Item Category" />
							</ListItemButton>
						)}
					</ListItem>
					<ListItem>
						{loadingSiteBranch ? (
							<CircularProgress />
						) : (
							<ListItemButton
								onClick={() => {
									callApiSiteBranch();
									setSingleSheetName('M_SiteBranch');
									setDownloadSingle(true);
									setLoadingSiteBranch(true);
								}}
							>
								<ListItemIcon>
									<DownloadIcon />
								</ListItemIcon>
								<ListItemText primary="Site Branch" />
							</ListItemButton>
						)}
					</ListItem>
					<ListItem>
						{loadingDocumentTypes ? (
							<CircularProgress />
						) : (
							<ListItemButton
								onClick={() => {
									callApiDocType();
									setSingleSheetName('M_DocumentTypes');
									setDownloadSingle(true);
									setLoadingDocumentTypes(true);
								}}
							>
								<ListItemIcon>
									<DownloadIcon />
								</ListItemIcon>
								<ListItemText primary="Document Type" />
							</ListItemButton>
						)}
					</ListItem>
					<ListItem>
						{loadingMembers ? (
							<CircularProgress />
						) : (
							<ListItemButton
								onClick={() => {
									callApiMembers();
									setSingleSheetName('T_Patients');
									setDownloadSingle(true);
									setLoadingMembers(true);
								}}
							>
								<ListItemIcon>
									<DownloadIcon />
								</ListItemIcon>
								<ListItemText primary="Members" />
							</ListItemButton>
						)}
					</ListItem>
					<ListItem>
						{loadingInstructions ? (
							<CircularProgress />
						) : (
							<ListItemButton
								onClick={() => {
									callApiInstuctionCat();
									setSingleSheetName('M_InstructionCategory');
									setDownloadSingle(true);
									setLoadingInstructions(true);
								}}
							>
								<ListItemIcon>
									<DownloadIcon />
								</ListItemIcon>
								<ListItemText primary="Instruction Category" />
							</ListItemButton>
						)}
					</ListItem>
					<BaseButton
						loading={loading}
						onClick={() => {
							setLoading(true);
							callApiServices();
							callApiDoctors();
							callApiItems();
							callApiItemCategory();
							callApiSiteBranch();
							callApiDocType();
							callApiMembers();
							callApiInstuctionCat();
							setDownloadMultiple(true);
						}}
						fullWidth
					>
						Download All
					</BaseButton>
				</List>
			</BaseDialog>
		</>
	);
};

export default observer(ExportToExcel);
