import { makeAutoObservable } from 'mobx';

class LayoutStore {
	sideBarState = true;

	constructor() {
		makeAutoObservable(this);
	}

	setSideBarState(state: boolean) {
		this.sideBarState = state;
	}
}

export default new LayoutStore();
