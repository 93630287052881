import React, { Suspense } from 'react';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { HLoadingIndicator } from '@hybrain/mui.ui.feedback.h-loading-indicator';
import DialogStore from '../stores/DialogStore';
import privateRoutes from '../modules/private/routes/private-routes';
import publicRoutes from '../modules/public/routes/public-routes';
import ResponseDialog from 'dialogs/ResponseDialog';

const ResponseDialogComponent = observer(() => (
	<ResponseDialog
		open={DialogStore.response.status}
		onClose={() => DialogStore.close()}
		type={DialogStore.response.type}
		message={DialogStore.response.content}
	/>
));

const RoutesList = () => {
	const routes = useRoutes([...publicRoutes, privateRoutes]);
	return routes;
};

const RoutesIndex = () => {
	return (
		<BrowserRouter>
			<Suspense
				fallback={
					<div className="flex flex-col min-h-screen">
						<HLoadingIndicator />
					</div>
				}
			>
				<ResponseDialogComponent />
				<RoutesList />
			</Suspense>
		</BrowserRouter>
	);
};

export default RoutesIndex;
