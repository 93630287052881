import api, { ApiProps } from 'api';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';

export type useApiProps<P, R> = {
	immediate?: boolean;
	onBeforeApiCall?: () => boolean | R;
	onSuccess?: (response: any, additionalPayload?: P) => void;
	onError?: (error: AxiosError) => void;
	onComplete?: () => void;
	dependencies?: any[];
	responseType?: 'array' | 'object';
	url?:
		| string
		| string[]
		| ((payload?: R extends void ? undefined : R) => string)
		| ((payload?: R extends void ? undefined : R) => string[]);
	action?: (payload: R extends void ? undefined : R, additionalPayload?: any) => Promise<AxiosResponse<any, any>>;
} & ApiProps &
	Omit<AxiosRequestConfig, 'url'>;

const useApi = <T = any, P = any, R = any>({
	immediate,
	url,
	success,
	error,
	redirect,
	method = 'GET',
	onSuccess,
	onError,
	onComplete,
	data,
	dependencies,
	responseType,
	onBeforeApiCall,
	action,
	...props
}: useApiProps<P, R>) => {
	const immediateRef = useRef(immediate);
	const [loading, setLoading] = useState(false);
	const [items, setItems] = useState<T>([] as any);

	const callAxios = useCallback((apiUrl: string, payload: any) => {
		const apiProps = { success, error, redirect };

		let apiAction;
		if (method === 'get' || method === 'GET') {
			apiAction = api(apiProps).get(apiUrl, { params: payload, ...props });
		}

		if (method === 'post' || method === 'POST') {
			apiAction = api(apiProps).post(apiUrl, data ?? payload, props);
		}

		if (method === 'put' || method === 'PUT') {
			apiAction = api(apiProps).put(apiUrl, data ?? payload, props);
		}

		if (method === 'patch' || method === 'PATCH') {
			apiAction = api(apiProps).patch(apiUrl, data ?? payload, props);
		}

		if (method === 'delete' || method === 'DELETE') {
			apiAction = api(apiProps).delete(apiUrl, props);
		}

		return apiAction;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const callApi = async (payload?: any, additionalPayloadOnSuccess?: P) => {
		const additionalPayload = onBeforeApiCall?.();

		if (additionalPayload === false) {
			return;
		}

		setLoading(true);

		let apiUrl: string | string[] = '';

		if (url && url instanceof Function) {
			apiUrl = url(additionalPayload as any);
		} else if (url) {
			apiUrl = url;
		}

		try {
			if (typeof apiUrl === 'string') {
				let res;

				if (action) {
					if (action instanceof Function && additionalPayload) {
						res = await action(additionalPayload as any, payload);
					} else {
						res = await action(payload);
					}
				} else {
					res = await callAxios(apiUrl, payload);
				}

				setItems(res?.data.response);
				onSuccess?.(res?.data, additionalPayloadOnSuccess);
			} else if (Array.isArray(apiUrl)) {
				const res = await Promise.all(apiUrl.map((url) => callAxios(url, payload)));

				let responseItems: any = [];
				let responseData: any = [];
				res.forEach((response) => {
					responseItems = [...responseItems, ...response?.data.response];
					responseData.push(response?.data);
				});

				onSuccess?.(responseData, additionalPayloadOnSuccess);
				setItems(responseItems);
			}
		} catch (err: any) {
			console.log(err);
			onError?.(err);
		} finally {
			setLoading(false);
			onComplete?.();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	};

	useEffect(() => {
		if (method !== 'get' && method !== 'GET' && immediateRef.current === undefined) {
			immediateRef.current = false;
		} else if (immediateRef.current === undefined && action === undefined) {
			immediateRef.current = true;
		} else if (immediateRef.current === undefined) {
			immediateRef.current = false;
		}

		if (immediateRef.current) {
			callApi();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, dependencies ?? []);

	return {
		loading,
		callApi,
		data: items,
	};
};

export default useApi;
