import React from 'react';
import './App.css';
import Routes from './routes';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import theme from './theme';

function App() {
	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<Routes />
			</ThemeProvider>
		</StyledEngineProvider>
	);
}
export default App;
