import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import BaseForm from 'form/BaseForm';
import FormTextField from 'form/FormTextField';
import BaseDialog from 'dialogs/BaseDialog';
import api from 'api';
import theme from 'theme';
import localforage from 'localforage';
import { useNavigate } from 'react-router-dom';
import DialogStore from 'stores/DialogStore';

interface ChangePasswordDialogProps {
	open: boolean;
	onClose: () => void;
	isFirstTimeLogin: boolean;
}

const ChangePasswordDialog: React.FC<ChangePasswordDialogProps> = ({ isFirstTimeLogin, ...props }) => {
	const [loading, setLoading] = useState(false);
	const [currentUserName, setCurrentUserName] = useState('');
	const [currentUser, setCurrentUser] = useState({} as any);
	const navigate = useNavigate();
	const DIALOG_TIMEOUT = 6000;

	useEffect(() => {
		(async () => {
			try {
				await localforage.getItem<any>('user').then((user) => {
					if (user != null) {
						let name = user.datacenter.Firstname + ' ' + user.datacenter.Middlename + ' ' + user.datacenter.Lastname;
						setCurrentUserName(name);
						setCurrentUser(user);
					}
				});
			} catch (err) {}
		})();
	}, []);

	const handleSubmit = async (values: any) => {
		try {
			setLoading(true);
			const re = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,32}$');
			const isPasswordValidFormat = re.test(values.ConfirmPassword);
			if (!isPasswordValidFormat) {
				DialogStore.error('Password does not meet requirements.');
				setTimeout(() => {
					DialogStore.close();
				}, DIALOG_TIMEOUT);
				setLoading(false);
				return;
			}

			let params = {
				UserInputCurrentPassword: values.CurrentPassword ?? '',
				HashedCurrentPassword: currentUser.Passphrase,
				NewPassword: values.NewPassword,
				ConfirmPassword: values.ConfirmPassword,
				UseraccessID: currentUser.UseraccessID,
				isRequireOldPassword: false,
				isForcedChangePassword: true,
				isCheckOldPassword: !isFirstTimeLogin,
				LastPasswordChangedBy: currentUserName,
			};

			let result = await api().patch<any>('/users/changepassword/' + currentUser.UseraccessID, params);

			setLoading(false);
			if (result.data?.result_status === 'OK') {
				let obj = currentUser;
				obj.Passphrase = result.data.response.Passphrase;
				obj.Keycode = result.data.response.Keycode;
				setCurrentUserName(obj);
				props.onClose();
				navigate('/login');
			}
		} catch {
			setLoading(false);
		}
	};

	return (
		<BaseDialog maxWidth="sm" title={'Change Password'} {...props}>
			<BaseForm
				loading={loading}
				dialog
				enableReinitialize
				initialValues={{
					NewPassword: currentUser?.NewPassword ?? '',
					ConfirmPassword: currentUser.ConfirmPassword ?? '',
				}}
				onSubmit={handleSubmit}
				submitButtonProps={{ style: { backgroundColor: theme.palette.primary.main } }}
			>
				<Grid container rowSpacing={3} columnSpacing={2}>
					<Grid item xs={12}>
						<p>Password should be atleast 8 characters long, alphanumeric and have atleast 1 special character. </p>
					</Grid>
					<Grid item xs={12}>
						<FormTextField
							name="NewPassword"
							label="New Password"
							type="password"
							inputProps={{
								autoComplete: 'new-password',
								form: {
									autoComplete: 'off',
								},
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<FormTextField
							name="ConfirmPassword"
							label="Re-type New Password"
							type="password"
							inputProps={{
								autoComplete: 'new-password-confirm',
								form: {
									autoComplete: 'off',
								},
							}}
						/>
					</Grid>
				</Grid>
			</BaseForm>
		</BaseDialog>
	);
};

export default ChangePasswordDialog;
