import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
const Login = lazy(() => import('../pages/login/LoginPage'));
const route: RouteObject[] = [
	{
		path: '/login',
		element: <Login />,
		children: [],
	},
];

export default route;
